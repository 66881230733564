<template>
  <div class="changemoble2">
    <van-nav-bar
      title="验证新手机号"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        v-model="mobile"
        name="mobile"
        label="新手机号"
        placeholder="新手机号"
        ref="mobile"
        :rules="[{ required: true, message: '请输入新手机号' }]"
      />
      <van-field
        v-model="sms"
        name="sms"
        center
        clearable
        label="短信验证码"
        placeholder="请输入短信验证码"
        :rules="[{ required: true, message: '请输入短信验证码' }]"
      >
        <template #button>
          <van-count-down v-show="!showTxt" :time="time" @finish="finish" ref="daojishi">
            <template v-slot="timeData">
              <span class="seconds">{{ timeData.seconds }}后重新发送</span>
            </template>
          </van-count-down>
          <van-button
            v-show="showTxt"
            type="danger"
            native-type="button"
            size="mini"
            :disabled="btnDisabled"
            @click="forgetpwd"
          >发送验证码</van-button>
        </template>
      </van-field>
      <div class="kongbai"></div>
      <div style="margin: 16px;">
        <van-button round block type="danger" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
export default {
  name: "ChangeMoble2",
  data() {
    return {
      sms: "",
      mobile: "",
      btnDisabled: true,
      showTxt: true, // 是否显示发送验证码按钮
      time: -1, // 倒计时时间
      oldMobile: "", // 原手机号
      mid: 0, // 会员id
      shareid: 0,
      merchid: 0,
    }
  },
  mounted(){
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "验证新手机号");
    this.init();
  },
  methods: {
    validator(val) {
      return /1\d{10}/.test(val); // 校验函数返回 true 表示校验通过，false 表示不通过
    },
    init() {
      this.oldMobile = this.$route.query.oldMobile
      this.mid = parseInt(this.$route.query.mid);
      console.log(this.mid, this.oldMobile);
      let _that = null;
      _that = this;
      if (!this.mid || this.mid <= 0) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .get(_that.$store.state.domain + "web/user/get_memberid")
          .then(res => {
            if (res.data.code == "100000") {
              _that.mid = parseInt(res.data.data);
            }
          })
          .catch(err => {
            console.log(err);
          })
      }
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 倒计时完成后执行
    finish() {
      this.btnDisabled = false;
      this.showTxt = true;
    },
    // 发送验证码
    forgetpwd() {
      let _that = null;
      _that = this;
      if (this.mobile && this.validator(this.mobile)) {
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios.post(
          _that.$store.state.domain + "web/user/send_sms",
          _that.$qs.stringify({
            tel: _that.mobile,
            type: 'login'
          })
        )
        _that.$refs.daojishi.reset();
        _that.time = 60 * 1000;
        _that.btnDisabled = true;
        _that.showTxt = false;
      }else{
        this.$refs.mobile.focus();
      }
    },
    onSubmit() {
      let _that = null;
      _that = this;
      if (this.mobile && this.validator(this.mobile)) {
        _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
        _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        _that.$axios
          .post(
            _that.$store.state.domain + "web/user/change_mobile_code",
            _that.$qs.stringify({
              mobile: _that.mobile,
              code: _that.sms
            })
          )
          .then(res => {
            if (res.data.code == "100000") {
              _that.changeMobile();
            }else{
              _that.$notify({
                message: res.data.msg ? res.data.msg : "短信验证码不正确",
                duration: 1000,
                type: "warning"
              })
            }
          })
          .catch(err => {
            console.log(err);
          })
      }else{
        this.$refs.mobile.focus();
      }
    },
    // 执行更换手机号
    changeMobile() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(
          _that.$store.state.domain + "web/user/rebindmob",
          _that.$qs.stringify({
            "new_mobile": _that.mobile,
            "old_mobile": _that.oldMobile,
            mid: _that.mid
          })
        )
        .then(res => {
          if (res.data.code == "100000") {
            _that.$router.go(-2);
          }else{
            _that.$notify({
              message: res.data.msg ? res.data.msg : "短信验证码不正确",
              duration: 1000,
              type: "warning"
            })
          }
        })
        .catch(err => {
          console.log(err);
        })
    }
  }
}
</script>
<style lang="less">
.changemoble2{
  width: 100%;
  height: 100%;
  background-color: #ededed;
  position: relative;
  z-index: 1;
  overflow: hidden;
  .van-nav-bar{
    .van-icon{
      color: #333;
    }
  }
  .van-form{
    .van-cell__value{
      flex: 1;
    }
    .van-cell__title{
      width: 80px;
    }
    .van-count-down{
      background-color: #bbb;
      padding: 1px 6px;
      .seconds{
        color: #ffffff;
        font-size: 12px;
      }
    }
    .kongbai{
      width: 100%;
      padding: 10px 0;
      background-color: #fff;
    }
  }
}
</style>